import logoLink from '../resources/efaflex.svg';
import companyLogoLink from '../resources/efaflex.svg';
import footerLogoLink from '../resources/efaflex_emeye-t-inside_logo.svg';

export const cssVariables = {
    primary_light: '#FFC073',
    primary: '#FF8C00',
    text_on_primary: 'white',
    text_on_primary_caller: 'white',

    secondary: '#4a4a4a',
    text_on_secondary: 'white',
    focus_active: '#4a4a4a',

    font: 'Arial, Helvetica, sans-serif',
    link_color: '#FF8C00',
    font_color: '#000',
    font_weight: '700',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#FF8C00',
    gridbox_header_text: '#ffffff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: '#4a4a4a',
    green: '#8cc800',
    connecting: '#c0c0c0',
    connected: '#8cc800',
    aborted: '#FF8C00',
    paused: '#FFE100',

    header_footer_seperator: '#FF8C00',
    panel_highlight: '#FF8C00',
    chat_background_right: '#FFD7A6',
    chat_background_left: '#dbdbdb',

    active: '#8cc800',
    inactive: '#adadad',
    text_on_active: 'white',
    text_on_inactive: 'white',

    dropdown_background: '#FFC073',

    phonebook_dropdown: '#FFC073',
    phonebook_dropdown_svg: '#000',

    pointer: '#FF8C00',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Efaflex';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
